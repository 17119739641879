
type PreloaderProps = {
	message?: string;
	size?: number;
	style?: any;
};

export default function Preloader({message, size, style}: PreloaderProps){

	return(
		<div style={{display: "inline-block", margin: 10, ...style}}>
			<div className="d-flex flex-column justify-content-center align-items-center">
				<img className="img-fluid preloader" src="/icon-transparent.png" alt="Logo Tribo Barber" style={{ width: size ? size : 100, height: size ? size : 100 }}/>
				{message && <div style={{ marginTop: 50 }}>{message}</div>}
			</div>
		</div>
	)
}