import { Navigate } from 'react-router-dom';

import { useAuth } from '../../contexts/AuthProvider';

export default function RouteAdmin({ children }){

	const { user } = useAuth();

	return (
		<>
			{(user && user.admin) ? (
				children
			):(
				<Navigate to="/login" />
			)}
		</>
	)
}