import { Alert as BootstrapAlert } from "react-bootstrap";
import { useAlert } from "../../contexts/AlertProvider";
import './index.scss';

export default function Alert(){

	const { alert, handleSetAlert } = useAlert();

	if(alert.visible === true) {
		setTimeout(() => {
			handleCloseAlert();
		}, alert.timeout ? alert.timeout : 9999)
	}

	function handleCloseAlert() {
		handleSetAlert({
			visible: false,
			variant: '',
			message: '',
			timeout: 0,
		});
	}

	return(
		<>
			{alert?.visible && <div className="div-alert">
				<div className="div-alert-child">
					<BootstrapAlert className="alert-component" onClick={handleCloseAlert} variant={alert.variant}>{alert.message}</BootstrapAlert>
				</div>
			</div>}
		</>
	)
}