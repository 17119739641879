import { Navigate, useLocation, useMatch } from 'react-router-dom';

import { useAuth } from '../../contexts/AuthProvider';

export default function RouteAuth({ children }){

	const { user } = useAuth();
	const { pathname } = useLocation();
	const params = useMatch("/:route/:establishmentSlug");

	return (
		<>
			{(user) ? (
				children
			):(
				<>
					{params ? (
						<Navigate to={`/barbearia-autenticacao?e=${params.params.establishmentSlug}`} />
					):(
						<Navigate to={`/autenticacao?r=${pathname}`} />
					)}
				</>
			)}
		</>
	)
}