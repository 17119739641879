import {createContext, useContext, useEffect, useState} from "react";
import firebase from "firebase/compat/app";
import {auth, UserCollection, remoteConfig} from "../config/firebase";
import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";

const AuthContext = createContext();

export function useAuth(){
	return useContext(AuthContext);
}

export async function getToken() {
	const token = await auth.currentUser.auth.currentUser.accessToken;
	return (token !== undefined) ? token: "";
}

export default function AuthProvider({children}){

	const [user, setUser] = useState();
	const [nameApple, setNameApple] = useState();
	const [configs, setConfigs] = useState(null);

	// REMOTE CONFIG
	useEffect(() => {
		const config = {
			cloud_messaging: JSON.stringify({ topicos: ["geral"] }),
			limit_appointments: 6,
			URL_FF_TO_SCHEDULE: "",
			USE_FF_TO_SCHEDULE: false
		};

		remoteConfig.defaultConfig = config;

		remoteConfig.fetchAndActivate().then((activated) => {
			const parameters = remoteConfig.getAll();
			let newConfig = {};

			Object.entries(parameters).forEach((item) => {
				const [key, entry] = item;
				if (key === "cloud_messaging") {
					newConfig[key] = JSON.parse(entry.asString());
				} else {
					newConfig[key] = entry.asString();
				}
			});
			setConfigs(newConfig);
		}).catch((error) => {
			console.error('Failed to fetch and activate configurations:', error);
		});
	}, []);

	useEffect(()=>{
		return auth.onAuthStateChanged(async (userState) => {
			if (userState) {
				try {
					let userDocument = await UserCollection.doc(userState.uid).get();
					let userData = userDocument.data();

					// USER INSERT
					if(!userData){

						let name = nameApple ? nameApple : userState.displayName;
						let avatar = null;
						let email = `${userState.email}`;
						let phoneNumber = userState.phoneNumber;

						if (userState.photoURL) {
							let avatarReference = firebase.storage().ref(`users/${userState.uid}`);
							let avatarUrl = userState.photoURL;

							if (userState.providerData[0].providerId === "facebook.com") {
								avatarUrl = `${userState.photoURL}?type=large`;
							}
							if (userState.providerData[0].providerId === "google.com") {
								avatarUrl = userState.photoURL.replace("s96-c", "s400-c");
							}

							let avatarImage = await downloadAvatar(avatarUrl);

							await avatarReference.putString(avatarImage, firebase.storage.StringFormat.BASE64);
							avatar = await avatarReference.getDownloadURL();
						}

						const userDataSave = {
							avatar: avatar,
							name: name,
							email: email,
							phoneNumber: phoneNumber,
							fcmToken: null,
							createdAt: firebase.firestore.Timestamp.now(),
							updatedAt: firebase.firestore.Timestamp.now(),
						};

						await UserCollection.doc(userState.uid).set(userDataSave);
					}

					// USER UPDATE
					if(userData){
						let userDataSave = {};
						let userUpdate = false;

						if (!userData.avatar && userState.photoURL) {
							let avatarReference = firebase.storage().ref(`users/${userState.uid}`);
							let avatar = null;
							let avatarUrl = userState.photoURL;

							if (userState.providerData[0].providerId === "facebook.com") {
								avatarUrl = `${userState.photoURL}?type=large`;
							}
							if (userState.providerData[0].providerId === "google.com") {
								avatarUrl = userState.photoURL.replace("s96-c", "s400-c");
							}

							let avatarImage = await downloadAvatar(avatarUrl);

							await avatarReference.putString(avatarImage, firebase.storage.StringFormat.BASE64);
							avatar = await avatarReference.getDownloadURL();

							userDataSave.avatar = avatar;
							userUpdate = true;
						}

						if (!userData.phoneNumber && userState.phoneNumber) {
							userDataSave.phoneNumber = userState.phoneNumber;
							userUpdate = true;
						}

						let name = nameApple ? nameApple : userState.displayName;
						if (!userData.name && name) {
							userDataSave.name = name;
							userUpdate = true;
						}

						if (userUpdate) {
							await UserCollection.doc(userState.uid).update(userDataSave);
						}
					}

					userDocument = await UserCollection.doc(userState.uid).get();
					userData = userDocument.data();
					userData['uid'] = userState.uid;
					setUser(userData);
				} catch (e) {
					alert('Problema na operação');
				}
			} else {
				setUser();
			}
		});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	const logIn = async(email, password) => {
		try{
			return await firebase.auth().signInWithEmailAndPassword(email.trim(), password);
		}catch(e){
			if (e.code === 'auth/invalid-email') {
				throw new Error('Email inválido');
			}
			if (e.code === 'auth/wrong-password') {
				throw new Error('Senha inválido');
			}
			if (e.code === 'auth/user-disabled') {
				throw new Error('Email desabilitado');
			}
			if (e.code === 'auth/user-not-found') {
				throw new Error('Email não cadastrado');
			}
			throw new Error('Problema na operação');
		}
	}

	async function signUp(name, email, password) {
		try {
			let credential = await firebase.auth().createUserWithEmailAndPassword(email.trim(), password);
			await credential.user.updateProfile({ displayName: name });
			let userDocument = await UserCollection.doc(credential.user.uid).get();
			if(userDocument){
				let userData = userDocument.data();
				if(userData){
					userData.name = name;
					await UserCollection.doc(credential.user.uid).update(userData);
				}
			}
		} catch (e) {
			if (e.code === "auth/invalid-email") {
				throw new Error("Email inválido");
			}
			if (e.code === "auth/weak-password") {
				throw new Error("Senha inválido");
			}
			if (e.code === "auth/email-already-in-use") {
				throw new Error("Email já cadastrado");
			}
			throw new Error("Problema na operação");
		}
	}

	async function logInWithGoogle(){
		try{
			let provider = new firebase.auth.GoogleAuthProvider();
			return await auth.signInWithPopup(provider);
		}catch(e){
			if (e.code === "auth/invalid-email") {
				throw new Error("Email inválido");
			}
			if (e.code === "auth/weak-password") {
				throw new Error("Senha inválido");
			}
			if (e.code === "auth/account-exists-with-different-credential") {
				throw new Error("Já existe uma conta, tente realizar o login com outro provedor");
			}
			if (e.code === "auth/email-already-in-use") {
				throw new Error("Email já cadastrado");
			}
			throw new Error("Problema na operação");
		}
	}

	async function logInWithFacebook() {
		try{
			let provider = new firebase.auth.FacebookAuthProvider();
			return await auth.signInWithPopup(provider);
		}catch(e){
			if (e.code === "auth/invalid-email") {
				throw new Error("Email inválido");
			}
			if (e.code === "auth/weak-password") {
				throw new Error("Senha inválido");
			}
			if (e.code === "auth/account-exists-with-different-credential") {
				throw new Error("Já existe uma conta, tente realizar o login com outro provedor");
			}
			if (e.code === "auth/email-already-in-use") {
				throw new Error("Email já cadastrado");
			}
			throw new Error("Problema na operação");
		}
	}

	async function logInWithApple() {
		try {
			const provider = new OAuthProvider('apple.com');
			provider.addScope('email');
			provider.addScope('name');

			const auth = getAuth();
			return signInWithPopup(auth, provider)
				.then((result) => {
					// The signed-in user info.
					const user = result.user;

					setNameApple(user.displayName);

					// Apple credential
					OAuthProvider.credentialFromResult(result);
				})
				.catch((e) => {
					if (e.code === "auth/invalid-email") {
						throw new Error("Email inválido");
					}
					if (e.code === "auth/weak-password") {
						throw new Error("Senha inválido");
					}
					if (e.code === "auth/account-exists-with-different-credential") {
						throw new Error("Já existe uma conta, tente realizar o login com outro provedor");
					}
					if (e.code === "auth/email-already-in-use") {
						throw new Error("Email já cadastrado");
					}
					throw new Error("Problema na operação");
				});

		}
		catch (e) {
			if (e.code === "auth/invalid-email") {
				throw new Error("Email inválido");
			}
			if (e.code === "auth/weak-password") {
				throw new Error("Senha inválido");
			}
			if (e.code === "auth/account-exists-with-different-credential") {
				throw new Error("Já existe uma conta, tente realizar o login com outro provedor");
			}
			if (e.code === "auth/email-already-in-use") {
				throw new Error("Email já cadastrado");
			}
			throw new Error("Problema na operação");
		}
	}

	async function downloadAvatar(photoURL) {
		return new Promise((resolve, reject)=>{
			var xhr = new XMLHttpRequest();
			xhr.onload = function() {
				var reader = new FileReader();
				reader.onloadend = function() {
					resolve(reader.result.split(',')[1]);
				}
				reader.readAsDataURL(xhr.response);
			};
			xhr.open('GET', photoURL);
			xhr.responseType = 'blob';
			xhr.send();
		})
	}

	async function logOut(){
		return await auth.signOut();
	}
	
	async function accountRemove() {
		if (!user) {
			return;
		}

		try {
			await UserCollection.doc(user.uid).delete();
		} catch (e) {
			throw new Error("Problema na operação: dados");
		}

		try {
			return await user.delete();
		} catch (e) {
			throw new Error("Problema na operação: conta");
		}
	}

	const value = {
		user,
		logIn,
		logInWithGoogle,
		logInWithFacebook,
		logInWithApple,
		signUp,
		logOut,
		accountRemove,
		configs
	}

	return (
		<AuthContext.Provider value={value}>
			{ children }
		</AuthContext.Provider>
	)
}
