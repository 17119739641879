import { createContext, useContext, useState } from "react";

const AlertContext = createContext();

export function useAlert(){
	return useContext(AlertContext);
}

export default function AlertProvider({children}){

	const [alert, setAlert] = useState({});

	function handleSetAlert(data){
		setAlert(data)
	}

	const value = {
		alert,
		handleSetAlert,
	}

	return (
		<AlertContext.Provider value={value}>
			{ children }
		</AlertContext.Provider>
	)
}