import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/messaging";
import 'firebase/compat/remote-config';

const app = firebase.initializeApp({
	apiKey: process.env.REACT_APP_APIKEY,
	authDomain: process.env.REACT_APP_AUTHDOMAIN,
	projectId: process.env.REACT_APP_PROJECTID,
	storageBucket: process.env.REACT_APP_STORAGEBUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
	appId: process.env.REACT_APP_APPID,
	measurementId: process.env.REACT_APP_MEASUREMENTID,
});

const AppointmentStatus = {
	pending: "Aguardando confirmação",
	confirmedAuto: "Confirmado",
	confirmedByEstablishment: "Confirmado",
	canceledByUser: "Cancelado pelo usuário",
	canceledByEstablishment: "Cancelado pelo estabelecimento",
	canceledByAbsence: "Cancelado por ausência",
};

const AppointmentStatusType = {
	pending: "pending",
	confirmedAuto: "confirmedAuto",
	confirmedByEstablishment: "confirmedByEstablishment",
	canceledByUser: "canceledByUser",
	canceledByEstablishment: "canceledByEstablishment",
	canceledByAbsence: "canceledByAbsence",
}

const UserCollection = firebase.firestore().collection('User');
const EstablishmentCollection = firebase.firestore().collection('Establishment');
const AppointmentCollection = firebase.firestore().collection('Appointment');
const SystemCitiesCollection = firebase.firestore().collection('SystemCities');

const auth = app.auth();
const firestore = app.firestore();
const storage = app.storage();

let messagingSupport = null;
// if(firebase.messaging.isSupported){
// 	messagingSupport = app.messaging();
// }

const messaging = messagingSupport;
const remoteConfig = app.remoteConfig();

async function send({to, title, body, image, topics}) {
	const data = {
		to: to,
		priority: "high",
		notification: {
			title: title,
			body: body,
			image: image,
			priority: "high",
			sound: "notification",
			android_channel_id: "tribo_barber_channel",
		},
		data: {
			type: "notification",
		},
		condition: topics?.map((topic)=>{ return ` '${topic}' in topics `}).join(" || "),
	};
	console.log(data);

	let response = await fetch("https://fcm.googleapis.com/fcm/send", {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `key=${process.env.REACT_APP_FCM_AUTHORIZATION_KEY}`,
		},
		body: JSON.stringify(data),
	});
	if(response.status!==200){
		throw new Error("Falha ao enviar notificação");
	}
}

export default app;

export {
	AppointmentStatus,
	AppointmentStatusType,
	UserCollection,
	EstablishmentCollection,
	AppointmentCollection,
	SystemCitiesCollection,
	auth,
	firestore,
	storage,
	messaging,
	remoteConfig,
	send,
}