import { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import TagManager from 'react-gtm-module';

import AuthProvider from "./contexts/AuthProvider";
import AlertProvider from "./contexts/AlertProvider";
import RouteAuth from "./components/RouteAuth";
import RouteAdmin from "./components/RouteAdmin";
import Preloader from "./components/Preloader";
import Alert from './components/Alert';

import './App.scss';

const Landing = lazy(()=> import("./screens/Landing"));
const LandingAntigo = lazy(()=> import("./screens/LandingAntigo"));
const Funcionalidades = lazy(()=> import("./screens/Funcionalidades"));
const Login = lazy(()=> import("./screens/Login"));
const Home = lazy(()=> import("./screens/Home"));
const Academy = lazy(()=> import("./screens/Academy"));
const AcademyCategory = lazy(()=> import("./screens/AcademyCategory"));
const Establishments = lazy(()=> import("./screens/Establishments"));
const Appointments = lazy(()=> import("./screens/Appointments"));
const Users = lazy(()=> import("./screens/Users"));
const User = lazy(()=> import("./screens/User"));
const Notifications = lazy(()=> import("./screens/Notifications"));
const Scripts = lazy(()=> import("./screens/Scripts"));
const TermsOfUse = lazy(()=> import("./screens/termsOfUse"));
const PrivacyPolicy = lazy(()=> import("./screens/PrivacyPolicy"));
const Establishment = lazy(()=> import("./screens/Establishment"));
const EstablishmentForm = lazy(()=> import("./screens/EstablishmentForm"));
const AppointmentForm = lazy(()=> import("./screens/AppointmentForm"));
const LoginForm = lazy(()=> import("./screens/LoginForm"));
const LoginEmailForm = lazy(()=> import("./screens/LoginEmailForm"));
const AppointmentsUser = lazy(()=> import("./screens/AppointmentsUser"));
const ProfileUser = lazy(()=> import("./screens/ProfileUser"));

declare const window: Window & { dataLayer: Record<string, unknown>[] };
TagManager.initialize({gtmId: `${process.env.REACT_APP_GTM_ID}`})

function AppLoader(){
	return (
		<div className="w-100 h-100 justify-content-center d-flex align-items-center">
			<Preloader/>
		</div>
	)
}

export default function App() {

	window.dataLayer.push({
		event: 'pageview'
	});

  	return (
		<Suspense fallback={<AppLoader/>}>
			<AlertProvider>
				<AuthProvider>
					<Alert/>
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<Landing/>}/>
							<Route path="/antigo" element={<LandingAntigo/>}/>
							<Route path="/funcionalidades" element={<Funcionalidades/>}/>
							<Route path="/login" element={<Login/>}/>
							<Route path="/home" element={<RouteAdmin><Home/></RouteAdmin>}/>
							<Route path="/scripts" element={<RouteAdmin><Scripts/></RouteAdmin>}/>
							<Route path="/academy" element={<RouteAdmin><Academy/></RouteAdmin>}/>
							<Route path="/academyCategory/:id" element={<RouteAdmin><AcademyCategory/></RouteAdmin>}/>
							<Route path="/establishments" element={<RouteAdmin><Establishments/></RouteAdmin>}/>
							<Route path="/appointments" element={<RouteAdmin><Appointments/></RouteAdmin>}/>
							<Route path="/users" element={<RouteAdmin><Users/></RouteAdmin>}/>
							<Route path="/user/:id" element={<RouteAdmin><User/></RouteAdmin>}/>
							<Route path="/notifications" element={<RouteAdmin><Notifications/></RouteAdmin>}/>
							<Route path="/establishment/:id" element={<RouteAdmin><Establishment/></RouteAdmin>}/>
							<Route path="/terms-of-use" element={<TermsOfUse/>}/>
							<Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
							<Route path="/barbearia-autenticacao" element={<LoginForm/>}/>
							<Route path="/autenticacao" element={<LoginForm/>}/>
							<Route path="/barbearia/:establishmentSlug" element={<EstablishmentForm/>}/>
							<Route path="/agendamento/:establishmentSlug" element={<AppointmentForm/>}/>
							<Route path="/email/:establishmentSlug" element={<LoginEmailForm/>}/>
							<Route path="/agendamentos/:establishmentSlug" element={<RouteAuth><AppointmentsUser/></RouteAuth>}/>
							<Route path="/perfil/:establishmentSlug" element={<RouteAuth><ProfileUser/></RouteAuth>}/>
							<Route path="/perfil" element={<RouteAuth><ProfileUser/></RouteAuth>}/>
							<Route path="*" element={<Landing/>}/>			
						</Routes>
					</BrowserRouter>
				</AuthProvider>
			</AlertProvider>
		</Suspense>
  	);
}
